import React, {useState} from "react";
import { deepCopyObj } from "../utils/object";

export const DataTablePreferenceContext = new React.createContext();

const initialState = {
    payouts: { 
        sort: { direction: 'desc', col: 'payout_date'} ,
        offset: 0,
        dateRange: undefined,
        amountRange: undefined
    },
    invoices: {
        unpaid: {
            sort: { col: 'issued_at', direction: 'desc' },
            filters: ['not_yet_due', 'overdue'],
            dateRange: undefined,
            amountRange: undefined,
            keyword: '',
            offset: 0,
            limit: undefined
        },
        paid: {
            sort: { col: 'issued_at', direction: 'desc' },
            filters: ['paid','voided','has_credit'],
            dateRange: undefined,
            amountRange: undefined,
            keyword: '',
            offset: 0,
            limit: undefined
        },
        all: { 
            sort: { col: 'issued_at', direction: 'desc' },
            filters: [],
            dateRange: undefined,
            amountRange: undefined,
            keyword: '',
            offset: 0,
            limit: undefined
        },
        scheduled: { 
            sort: { direction: 'asc', col: 'issued_at'},
            dateRange: undefined,
            amountRange: undefined,
            keyword: '',
            offset: 0,
            limit: undefined
        },
        recurring: { 
            sort: { direction: 'desc', col: 'issued_at'},
            dateRange: undefined,
            amountRange: undefined,
            keyword: '',
            offset: 0,
            limit: undefined
        },
        draft: { 
            sort: { direction: 'desc', col: 'updated_at'},
            dateRange: undefined,
            amountRange: undefined,
            keyword: '',
            offset: 0,
            limit: undefined
        },
        failed: {
            sort: { direction: 'desc', col: 'updated_at'}
        },
        activeTab: ''
    },
    customers: {
        individuals: {
            sort: { direction: 'desc', col: 'last_invoiced_at'},
            keyword: '',
            offset: 0,
            limit: undefined
        },
        groups: {
            sort: { direction: 'desc', col: 'last_invoiced_at'},
            keyword: '',
            offset: 0,
            limit: undefined
        }
    },
    transactions: { 
        sort: { direction: 'desc', col: 'created_at'},
        filters: [],
        dateRange: undefined,
        amountRange: undefined,
        keyword: '',
        offset: 0,
        limit: undefined
    }, 
};

const DataTablePreferenceProvider = ({ children }) => {
    //default state of each data table
    const [preferences, setPreferences] = useState(deepCopyObj(initialState)); // need a fresh obj

    const updatePreferences = ({table, tab, key, value}) => {
        if (!table) return undefined;
        if (tab) {
            preferences[table][tab][key] = value;
        } else {
            preferences[table][key] = value;
        }
    }

    const getPreference = ({table, tab, key}) => {
        if (table && !tab && !key) return preferences[table];
        if (table && !key) return preferences[table][tab];
        return tab ? preferences[table][tab][key] : preferences[table][key];
    }

    const resetPreference = () => setPreferences(deepCopyObj(initialState));

    return (
        <DataTablePreferenceContext.Provider value={{
            preferences,
            updatePreferences,
            getPreference,
            resetPreference
        }}>
            {children}
        </DataTablePreferenceContext.Provider>
    )
}

export default DataTablePreferenceProvider;